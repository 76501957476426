import React from 'react';
import Map from './Map';
import Legend from './Legend';
import styled from 'styled-components';

function App() {
  return (
    <Wrapper className="App">
      <Map />
      <Legend />
    </Wrapper>
  );
}

export default App;

const Wrapper = styled.div `
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 1fr;
  margin: 0 auto;
  @media only screen and (max-width: 700px) {
    display: block;
  }
`;
