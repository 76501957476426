import React from 'react';
import styled from "styled-components";

export default function Legend() {
    return (
        <div>
            <Headline>Tempe Neighborhoods</Headline>
            <Deck><em>Median home prices, Nov. 2019</em></Deck>
            <List>
                <Item>
                    <Neighborhood>University Park</Neighborhood>
                    <Cost>$514,800</Cost>
                </Item>
                <Item>
                    <Neighborhood>McClintock Manor</Neighborhood>
                    <Cost>$396,900</Cost>
                </Item>
                <ItemRed>
                    <Neighborhood>Maple-Ash</Neighborhood>
                    <Cost>$394,300</Cost>
                </ItemRed>
                <ItemRed>
                    <Neighborhood>Broadmor</Neighborhood>
                    <Cost>$386,700</Cost>
                </ItemRed>
                <Item>
                    <Neighborhood>Shalimar</Neighborhood>
                    <Cost>$369,700</Cost>
                </Item>
                <Item>
                    <Neighborhood>Date Palm Manor</Neighborhood>
                    <Cost>$358,900</Cost>
                </Item>
                <Item>
                    <Neighborhood>Rural-Geneva</Neighborhood>
                    <Cost>$333,500</Cost>
                </Item>
                <Item>
                    <Neighborhood>Cyprus Southwest</Neighborhood>
                    <Cost>$326,700</Cost>
                </Item>
                <Item>
                    <Neighborhood>Clark Park</Neighborhood>
                    <Cost>$314,700</Cost>
                </Item>
                <Item>
                    <Neighborhood>Hughes Acres</Neighborhood>
                    <Cost>$308,300</Cost>
                </Item>
                <Item>
                    <Neighborhood>McClintock</Neighborhood>
                    <Cost>$304,500</Cost>
                </Item>
                <Item>
                    <Neighborhood>Tempe Gardens</Neighborhood>
                    <Cost>$299,400</Cost>
                </Item>
                <Item>
                    <Neighborhood>Hudson Manor</Neighborhood>
                    <Cost>$297,800</Cost>
                </Item>
                <Item>
                    <Neighborhood>Gililland</Neighborhood>
                    <Cost>$297,400</Cost>
                </Item>
                <Item>
                    <Neighborhood>Mach 8</Neighborhood>
                    <Cost>$296,800</Cost>
                </Item>
                <Item>
                    <Neighborhood>Holdeman</Neighborhood>
                    <Cost>$293,700</Cost>
                </Item>
                <Item>
                    <Neighborhood>Lindon Park</Neighborhood>
                    <Cost>$284,200</Cost>
                </Item>
                <Item>
                    <Neighborhood>University Heights</Neighborhood>
                    <Cost>$272,300</Cost>
                </Item>
                <Item>
                    <Neighborhood>Jen Tilly Terrace</Neighborhood>
                    <Cost>$254,000</Cost>
                </Item>
                <Item>
                    <Neighborhood>Riverside</Neighborhood>
                    <Cost>$218,700</Cost>
                </Item>
                <Item>
                    <Neighborhood>Allegre Community</Neighborhood>
                    <Cost>$185,600</Cost>
                </Item>
            </List>

        </div>
    )
}

const Headline = styled.h2 `
    margin: 30px 20px 0 20px;
    padding: 0;
`;

const Deck = styled.p `
    margin: 0 20px;
    padding: 0;
`;

const List = styled.ul `
    margin: 20px;
    padding: 0;
`;

const Item = styled.li `
    border-top: 1px solid lightgrey;
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin: 5px 0 0 0;
    padding: 5px 0 0 0;
    &:first-of-type {
        border-top: 0;
        padding-top: 0;
        margin-top: 0;
    }
`;

const ItemRed = styled.li `
    border-top: 1px solid lightgrey;
    color: #DF0900;
    display: grid;
    font-weight: 600;
    grid-template-columns: 3fr 1fr;
    margin: 5px 0 0 0;
    padding: 5px 0 0 0;
`;

const Neighborhood = styled.div `
    margin: 0;
    padding: 0;
    font-size: 15px;
`;

const Cost = styled.div `
    margin: 0;
    padding: 0;
    font-size: 15px;
`;
