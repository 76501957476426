import React from 'react'
import tempe from "./tempe.png"
import styled from "styled-components";

export default function Map() {
    return (
        <div>
            <Image src={tempe} alt="Tempe"/>
        </div>
    )
}

const Image = styled.img `
    width: 100%;
`;
